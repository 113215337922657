<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 @keyup.enter.native="search(1)"
                 label-width="100px">
            <el-form-item prop="archivesBasicId" label="元数据名称：">
                <el-select v-model="searchForm.archivesBasicId" placeholder="请选择元数据名称" clearable class="selectBox" style="width: 160px">
                    <el-option v-for="item in archivesBasicOpt" :key="item.id" :label="item.basicName"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="selectBy" label="选项：">
                <el-select placeholder="请选择" clearable v-model="searchForm.selectBy" style="width: 160px">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('metadata_option')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="archivesBasicValue" label="输入搜索：" label-width="120px">
                <el-input v-model.trim="searchForm.archivesBasicValue" maxlength="10" placeholder="请输入名称（限10字）"
                          clearable style="width: 160px"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search(1)" size="small">查询</el-button>
                <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <el-form-item class="f_r p_r2">
                <el-button type="primary" icon="el-icon-plus" size="small" v-show="hasPermissionButton('collection:optionList:add')"
                           @click="addEdit(null, 'add')">新增
                </el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 285px)"
                    class="table" ref="multipleTable">
                <el-table-column prop="archivesBasicValue" label="名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="basicName" label="元数据名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="selectBy" label="选项" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("metadata_option", scope.row.selectBy ,'')}}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" width="120" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-show="hasPermissionButton('collection:optionList:edit')"
                                   @click="addEdit(scope.row, 'edit')">修改
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermissionButton('collection:optionList:delete')"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <OptionForm ref="optionForm" @refreshDataList="search()"></OptionForm>
    </div>
</template>

<script>
    import OptionForm from './optionForm'

    export default {
        components: {
            OptionForm
        },
        data() {
            return {
                searchForm: {
                    archivesBasicId: '',
                    selectBy: '',
                    archivesBasicValue: '',
                    current: 1,
                    size: 10,
                },
                archivesBasicOpt: [],
                loading: false,
                dataList: [],
                total: 0,
            }
        },
        mounted() {
            this.getArchives()
            this.search(1)
        },
        methods: {
            // 查询
            search(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.collection.selectArchivesBasicMultiselect, this.searchForm, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 获取元数据名称
            getArchives() {
                this.$axios(this.api.collection.selectAllTextArchivesBasic).then((res) => {
                    if (res.status) {
                        this.archivesBasicOpt = res.data
                    }
                })
            },
            // 新增
            addEdit(row, method) {
                this.$refs.optionForm.init(row, method)
            },
            // 删除
            del(id) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.collection.multiselectRemoveById + '/' + id, {}, 'delete').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                            this.search()
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            },
            //重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped>

</style>
